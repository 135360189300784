li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined,
.MuiSelect-select em,
.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
  color: var(--text);
}

.MuiSelect-select em,
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  font-size: 15px;
  font-family: Montserrat;
}

.MuiInputBase-root.MuiOutlinedInput-root,
li.MuiMenuItem-root span {
  width: 100%;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  font-size: 14px;
  font-family: Montserrat;
  line-height: 1.42857143;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  width: 1.8em;
  height: 1.8em;
  margin-top: -5px;
}

.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
  background-color: var(--background);
  border-radius: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
  border-radius: 0;
  background-color: var(--secondary);
  box-shadow: 0px 2px 8px 0.44px #00000063;
}

ul.MuiList-root li.Mui-selected {
  background-color: var(--primary_dark);
  transition: filter 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

ul.MuiList-root li:hover {
  filter: brightness(var(--bright));
  background-color: var(--secondary);
}

ul.MuiList-root li.Mui-selected:hover {
  background-color: var(--primary_dark);
  filter: brightness(var(--bright));
}
