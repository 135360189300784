main.threats {
  position: absolute;
  font-family: Montserrat;
  overflow: hidden;
  padding: 8px !important;
  flex-direction: row;
  display: flex;
  flex: 1 1;
  width: calc(100% - 70px);
}

.threats .page {
  overflow: hidden;
}

.threats .card {
  margin-bottom: 14px;
}

.threats .columns {
  margin-top: 20px;
  height: 100%;
}

.threats .columns .full {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 14px 0px;
}

.threats .columns .full .card {
  margin: 0px;
}

.threats .columns .full .internal {
  grid-area: 1 / 1 / 2 / 2;
}
.threats .columns .full .external {
  grid-area: 2 / 1 / 3 / 3;
}
.threat .columns .full .card {
  margin: 0px;
}

.threats .page .columns .full .card .body {
  margin: 10px 0;
  height: 100%;
}

.threats .card .risk-cell {
  height: 100%;
  padding: 9px;
  font-weight: 600;
}
.threats .card .risk-box {
  position: absolute;
  inset: 0;
  text-align: center;
}

.threats .dash-table .host svg {
  height: 24px;
  width: 30px;
}

.threats .dash-table.threats-table tbody tr:hover {
  cursor: default;
}
.threats .dash-table.threats-table tbody tr td {
  padding: 6px 25px;
}

.threats .threats-table .risk-last {
  position: relative;
}

.threats .threats-table tbody tr {
  height: 41px;
}

.modal-pnl .actions.center .btn {
  padding: 5px 30px;
}

.threats .metrics .help .btn.generator-btn {
  width: 150px;
}
