main.monitoring {
  padding: 0 !important;
}

.monitoring .page {
  position: fixed;
  top: var(--nav_height);
  left: 70px;
  right: 0;
  bottom: 0;
}

.monitoring .page .card {
  margin-bottom: 14px;
}

.monitoring .dashboard {
  display: grid;
  position: relative;
  height: 100%;
  grid-template-columns: 0.9fr 1.1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.monitoring .dashboard .left-col {
  grid-area: 1 / 1 / 4 / 2;
  padding-right: 14px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
}

.monitoring .dashboard .left-col > *:last-child {
  margin-bottom: 0;
}

.monitoring .left-col .card.outage {
  flex-grow: 2;
}

.monitoring .left-col .card.outage .body {
  height: calc(100% - 40px);
}
.monitoring .left-col .card.outage .dash-table-empty {
  flex-grow: 2;
  display: flex;
  align-items: center;
}
.monitoring .left-col .card.outage .dash-table-empty i {
  flex-grow: 2;
  text-align: center;
}

.monitoring .spark {
  border-bottom: 8px solid;
  margin-bottom: 14px;
  background-color: var(--secondary);
}

.monitoring .spark .token {
  height: 100%;
  display: flex;
  text-align: center;
}

.monitoring .spark .token .bed {
  margin: auto;
  padding: 12px;
  font-size: 20px;
  padding-bottom: 0;
}

.monitoring .spark .head {
  padding: 12px 20px 0;
}

.monitoring .spark .metric {
  position: relative;
  font-size: 42px;
  font-weight: 800;
  text-align: center;
  padding: 0 20px 5px 0;
}

.monitoring .spark .metric span {
  position: absolute;
  top: 7px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 4px;
}

.monitoring .dashboard .right-col {
  grid-area: 1 / 2 / 4 / 4;
}

.monitoring .dashboard .card.addresses {
  height: 100%;
}

.monitoring .heading .btn.settings {
  background-color: transparent;
  font-size: 18px;
  padding: 4px 9px;
  margin-right: -10px;
}

.monitoring .left-col .card {
  min-height: 325px;
}

.monitoring .card.hosts {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.monitoring .card.hosts .empty {
  text-align: center;
}

.monitoring .menu-sorting {
  right: 0;
  position: absolute;
  z-index: 1;
}

.monitoring .hosts .actions > * {
  margin-top: -2px;
}

.monitoring .grid {
  position: relative;
  top: 28px;
  max-height: calc(100% - 25px);
  overflow: hidden;
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  grid-template-rows: auto 1fr;
}

.monitoring .grid .item,
.monitoring .top-outages .item {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0s;
}
.monitoring .grid .item:hover {
  background-color: var(--list_transparency);
}

.monitoring .grid .item p {
  margin: 0;
  width: calc(100% - 41px);
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.monitoring .item .indicator {
  border-radius: 200px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 12px;
}

.monitoring .item .indicator i.toggling {
  margin-left: 3px;
}

.monitoring .grid .ctx-menu-btn {
  position: absolute;
  right: 0;
  padding: 10px 12px;
}

.monitoring .card.history {
  flex-flow: column;
  display: flex;
  height: 320px;
  position: relative;
}

.monitoring .card.history .body {
  flex: 1 1 auto;
}

.monitoring .history .chart-container {
  width: 100%;
  height: 100%;
}

.monitoring .top-outages .item:hover {
  cursor: default;
}
.monitoring .top-outages .item p {
  margin: 4px 8px;
}

.modal-pnl {
  width: 400px;
}

.modal-pnl.endpoint-modal .footer {
  margin-top: 0px;
}

.monitoring .info-list {
  margin-top: 0px;
}

/* .modal-pnl.endpoint-modal .field {
  cursor: text;
  filter: brightness(1); 
  background-color: #00000000;
} */
