/* ---------------------------------------------------
    UserCard Style
----------------------------------------------------- */

.topnav .dropdown {
  float: right;
  margin-right: 1px;
  /* overflow: hidden; */
}

.topnav .dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: var(--text);
  padding: 0 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.topnav .dropdown:hover .dropbtn {
  filter: brightness(var(--dim));
  cursor: pointer;
}

.topnav .anchor {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 150%;
  z-index: -1;
}

.topnav .dropdown-content {
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 50;
  /* height: 0px; */
  display: none;

  color: var(--text);
  background-color: var(--secondary);
}

.topnav .dropdown-content.open {
  overflow-y: auto;
  height: auto;
  display: block;
}

.topnav .dropdown-content .drop-entry,
.topnav .dropdown-content .drop-entry a {
  float: right;
  padding: 12px 16px;
  background-color: var(--secondary);
  color: var(--text);
  text-decoration: none;
  display: block;
  text-align: left;
  width: 100%;
}

.topnav .dropdown-content .drop-entry:hover {
  filter: brightness(90%);
  cursor: pointer;
}

/* ---------------------------------------------------
   Toggle button style
----------------------------------------------------- */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  z-index: 1;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
