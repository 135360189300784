/* ---------------------------------------------------
    NETWORK CHOICE LIST
----------------------------------------------------- */

.net-stats {
  position: fixed;
  top: var(--nav_height);
  left: 70px;
  width: 440px;
  bottom: 0;
  z-index: 1;
  background-color: var(--secondary);
  box-shadow: 5px 5px 27px 0px #0000007d;

  transition: left var(--panel_transition);
}

.net-stats.closed {
  left: -440px;
}

.net-stats .header {
  background-color: var(--highlight_1);
  background: linear-gradient(232deg, var(--highlight_1), var(--primary_dark));
  padding: 12px 20px;
  font-size: 18px;
}

.net-stats .header .btn {
  background-color: transparent;
  float: right;
  font-size: 25px;
  margin-top: -7px;
  border-radius: 40px;
  padding: 2px 12px;
  transition: background-color 200ms;
}

.net-stats .header .btn:hover {
  background-color: #ffffff80;
}

.net-stats > * {
  padding: 15px;
}

.net-stats .list {
  padding-top: 0;
  height: calc(100% - var(--nav_height) - 65px);
  overflow-y: auto;
}

.net-stats .list .net {
  background-color: var(--list_transparency);
  display: flex;
  margin-bottom: 12px;
  border-bottom: solid 5px;
  min-height: 40px;
}

.net-stats .list .net.initializer {
  border-bottom: 0;
}

.net-stats .list .net.initializer .info {
  text-align: center;
}

.net-stats .list .risk {
  padding: 12px;
  padding: 7px 11px 3px 11px;
  min-width: 35.56px;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
}

.net-stats .list .info {
  vertical-align: middle;
  margin: auto 12px;
  width: 100%;
}

.net-stats .list .net:hover {
  filter: brightness(var(--bright));
  background-color: var(--list_transparency_hover);
  cursor: pointer;
}

.net-stats .list .info span {
  display: flex;
  opacity: 0.7;
}

.net-stats .list .indicator {
  padding: 7px 10px;
  z-index: 1;
  position: relative;
}

.net-stats .list .indicator i {
  transition: transform 0.2s;
}

.net-stats .list .indicator.open i {
  transform: rotate(-180deg);
}

.net-stats .list .indicator:hover {
  background-color: #80808075;
}

.net-stats .subnet-list {
  transition: height 0.2s;
  border: 0;
  margin-top: -12px;
  margin-bottom: 12px;
  overflow: hidden;
}

.net-stats .subnet-list .sub {
  margin-left: 38px;
  margin-bottom: 0;
}

.net-stats .subnet-list span.right {
  margin-right: 32px;
}

.net-stats .menu-search {
  padding: 0 15px;
  margin-top: 10px;
  position: relative;
  display: flex;
}

.net-stats .menu-search .search-bar {
  flex-grow: 2;
  margin-left: 8px;
  margin-right: 8px;
}

.net-stats .menu-search .search-bar,
.net-stats .menu-search .btn {
  height: 40px;
}

.net-stats .menu-search .btn {
  max-width: 40px;
  flex-grow: 1;
  padding: 9px 8px 0px 0;
}

.net-stats .menu-search .search-bar input {
  width: 100%;
  border-radius: 0;
}

.net-stats .menu-search .search-bar .search-ico .ico-body {
  background-color: var(--highlight_2);
  padding: 5px 10px;
}

.net-stats .menu-search .search-bar .search-ico {
  right: -15px;
  overflow: hidden;
  width: 60px;
}

.net-stats .menu-search p {
  display: flex;
  align-items: flex-end;
}

.net-stats .menu-sorting {
  margin-bottom: 12px;
}
