/* -----------------------------------------------------
                    App Specific 
--------------------------------------------------------*/

.hidden {
  display: none;
}

.full {
  position: relative;
  width: 100%;
  height: 100%;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

label {
  color: var(--text);
  margin-bottom: 0px;
  font-weight: 100;
}

#root main {
  margin-left: 70px;
  padding: 30px;
  color: var(--text);
  height: calc(100% - var(--nav_height));
}

#root {
  position: relative;
  width: 100%;
  min-height: 100%;
  bottom: 0;
  top: 0;
  color: var(--text);
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}

.form-control::-webkit-input-placeholder {
  color: var(--placeholder);
  font-style: italic;
}

/* -----------------------------------------------------
                    Animations 
--------------------------------------------------------*/

.spin {
  /* background: */
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/* -----------------------------------------------------
                    Generic CSS 
--------------------------------------------------------*/

.hidden {
  display: none;
}

.static:hover {
  cursor: default !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

.anchor {
  position: relative;
}

/* i.fa, i.fas {
  margin-right: 12px;
} */

.sep-h {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--tertiary);
}

.sep-v {
  height: 100%;
  margin-left: 15px;
  margin-right: 15px;
  border-left: 1px solid var(--tertiary);
}

.page-loader {
  position: absolute;
  left: 70px;
  top: var(--nav_height);
  width: calc(100% - 70px);
  height: calc(100% - var(--nav_height));
  background-color: #00000040;
  z-index: 1;
}
.page-loader h1 {
  transform: translate(-50%, -50%);
  position: relative;
  left: 50%;
  top: 42%;
  text-align: center;
}

svg.host-ico > * {
  fill: var(--text);
}

.page {
  overflow-y: auto;
  flex-direction: column;
  flex: 1 1;
  display: flex;
  padding: 14px;
}

/* ============= Button ============= */

.btn {
  background-color: var(--primary_dark);
  color: var(--text) !important;
  border-radius: 0;
  border: 0;
  transition: filter 200ms;
}
.btn-block {
  display: block;
  width: 100%;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.btn:hover {
  filter: brightness(var(--bright));
  color: var(--text);
}
.btn:focus {
  outline-width: 1px;
  outline-style: double;
  outline-color: var(--text);
  color: inherit;
}

.btn.quart {
  background-color: var(--highlight_2) !important;
  color: var(--text) !important;
}

.btn.gray {
  background-color: #969696;
}

.btn.disabled,
.toggle.disabled,
.btn[disabled],
fieldset[disabled] .btn,
.btn.disabled:hover,
.btn[disabled]:hover,
fieldset[disabled] .btn:hover,
.drop-down .op[disabled] {
  filter: saturate(0) brightness(var(--disabled));
  pointer-events: none;
  opacity: 1;
  cursor: default;
}

.btn.small {
  padding: 4px 8px;
  line-height: 1.3;
}

.btn.second {
  background-color: var(--highlight_1);
}

.actions.center {
  justify-content: center;
}

*[data-demo="true"] {
  filter: saturate(50%) brightness(var(--disabled)) !important;
  pointer-events: none;
}

/* ============= Jumbotron ============= */

.jumbo {
  position: absolute;
  background-color: #0000003b;
  padding: 35px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  inset: 50px 10px 10px;
}

.jumbo.error {
  border-top: solid 6px var(--error);
  border-bottom: solid 6px var(--error);
  text-align: center;
}

.jumbo.error:hover {
  cursor: default;
}

.jumbo > * {
  margin-bottom: 15px;
}

.jumbo > *:last-child {
  margin-bottom: 0px;
}

/* ============= React-Scrollbar ============= */

div#react-scrollbar > *:last-child > * {
  margin-left: 2px;
  background-color: var(--primary) !important;
}

div#react-scrollbar-horiz > *:last-child > * {
  background-color: var(--primary) !important;
}

/* -----------------------------------------------------
                      RC-Slider
--------------------------------------------------------*/

.rc-slider-step {
  background-color: var(--background);
  height: 6px;
}
.rc-slider-track {
  background-color: var(--primary_dark);
  height: 6px;
  z-index: 1;
}
.rc-slider-disabled.rc-slider-track {
  background-color: #868585;
}
.rc-slider-disabled {
  background-color: transparent;
}
.rc-slider-handle {
  z-index: 2;
  border: 0;
  background-color: var(--text) !important;
}

/* -----------------------------------------------------
                      Tippy Tooltips
--------------------------------------------------------*/
.tippy-popper .tippy-content {
  font-weight: 100;
  padding: 7px 12px !important;
  font-size: 13.5px;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.material-theme .tippy-arrow {
  border-right: 7px solid var(--tooltip_bg);
}
.tippy-popper[x-placement^="left"] .tippy-tooltip.material-theme .tippy-arrow {
  border-left: 7px solid var(--tooltip_bg);
}
.tippy-popper[x-placement^="top"] .tippy-tooltip.material-theme .tippy-arrow {
  border-top: 7px solid var(--tooltip_bg);
}
.tippy-popper[x-placement^="bottom"] .tippy-tooltip.material-theme .tippy-arrow {
  border-bottom: 7px solid var(--tooltip_bg);
}

.tippy-tooltip.material-theme {
  background-color: var(--tooltip_bg) !important;
}

.tippy-popper .tippy-content i {
  margin-right: 14px;
}

/* ============= Custom Form ============= */

form .row.password {
  position: relative;
}

form .row.password .toggler {
  position: absolute;
  padding: 7px 16px;
  right: 30px;
  transition: filter 200ms;
}

form .row.password .toggler:hover {
  cursor: pointer;
  filter: brightness(var(--dim));
}

/* -----------------------------------------------------
                      Modal
--------------------------------------------------------*/

.modal-pnl {
  margin: 35px 10px 0;
}

.modal-pnl .header {
  background-color: var(--primary_dark);
  text-align: center;
  font-size: 18px;
  margin: -75px -50px 20px;
  padding: 13px;
}

.modal-pnl .modal-form {
  display: flex;
  flex-direction: column;
}

.modal-pnl .footer {
  display: flow-root;
  margin: 25px -50px -28px;
  /* background-color: #1414144f; */
  padding: 15px;
}

.modal-pnl .info {
  max-width: 380px;
  display: flex;
  margin: 30px 0;
}

.modal-pnl .modal-input {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.modal-pnl .modal-error {
  padding: 5px 10px;
  margin: -10px 15px 10px;
  border-left: solid 4px var(--error);
  font-weight: 600;
  color: var(--error);
  background-color: var(--console_transparency);
}

.ReactModal__Overlay {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open,
.MuiBackdrop-root.MuiModal-backdrop {
  background-color: #00000040 !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 0 !important;
  color: var(--text);
}

/* -----------------------------------------------------
                      Checkbox
--------------------------------------------------------*/

.checkbox-styled-box {
  border-radius: 2px;
}

.checkbox-styled-box,
.radio-button-styled-button {
  background-color: rgb(8, 11, 12);
  border-top-color: rgb(63, 68, 69);
  border-right-color: rgb(63, 68, 69);
  border-bottom-color: rgb(63, 68, 69);
  border-left-color: rgb(63, 68, 69);
  border-color: #8d9496;
}

.checkbox-styled-box {
  transition: border-color 200ms ease;
  height: 16px;
  width: 16px;
  background-color: var(--background);
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  margin-top: 2px;
  vertical-align: top;
  cursor: pointer;
}

.checkbox-styled-box::after,
.checkbox-styled-box::before,
input:checked + .radio-button-styled-button::before {
  background-color: rgb(14, 99, 148);
}

.checkbox-styled-box::after,
.checkbox-styled-box::before {
  background-color: rgb(0, 91, 182);
}

.checkbox-styled-box:after,
.checkbox-styled-box:before,
input:checked + .radio-button-styled-button:before {
  background-color: #329ad6;
}

.checkbox-styled-box:after {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 8px;
  left: 1px;
}

.checkbox-styled-box:after,
.checkbox-styled-box:before {
  transition: opacity 0.5 ease;
  -ms-transform-origin: left top;
  transform-origin: left top;
  box-sizing: border-box;
  position: absolute;
  height: 0;
  width: 3px;
  background-color: #16b;
  display: inline-block;
  border-radius: 4px;
  content: " ";
}

/* -----------------------------------------------------
                     Input
--------------------------------------------------------*/

input.form-control {
  background-color: var(--background);
  border-radius: 0;
  border-image: none;
  border-width: 0;
  /* border-color: #f0f0f0; */
  /* border-style: solid; */
  box-shadow: 0 0 black;
  color: var(--text);
}

input.form-control:focus {
  box-shadow: 0 0 black;
  border-color: #48bcff;
}

/* -----------------------------------------------------
                      Tool bar
--------------------------------------------------------*/

.actions {
  display: flex;
}

.actions > * {
  margin: 0 5px;
}

.actions > span {
  vertical-align: middle;
  padding: 6px;
}

.actions > label {
  vertical-align: middle;
  margin: auto 12px auto 22px;
}

.actions > input {
  border: 0;
  border-radius: 50px;
  padding: 7px 14px;
  width: 100%;
}
/* -----------------------------------------------------
                      Context Menu
--------------------------------------------------------*/

nav.react-contextmenu {
  z-index: 100000;
  background-color: var(--secondary_dark);
  border: 1px solid #90909080;
  box-shadow: 0 0 12px 0px #00000036;
}
.react-contextmenu-item.heading {
  pointer-events: none;
}

.react-contextmenu-item.header {
  font-size: 12px !important;
  pointer-events: none;
  padding: 0px;
}
.react-contextmenu-item.header p {
  margin: 0;
  text-align: center;
  opacity: 0.75;
}

.react-contextmenu-wrapper {
  height: 100%;
}

nav.react-contextmenu > *[aria-orientation="horizontal"] {
  padding: 0;
  margin: 0 9px;
  border-top: solid 1px #c3c3c361;
  border-bottom: solid 1px #3e3e3e61;
}
nav.react-contextmenu > *[aria-orientation="horizontal"]:hover {
  cursor: default;
  filter: brightness(100%);
}

nav.react-contextmenu > * {
  padding: 10px 20px;
  /* margin: 4px 0; */
  background-color: var(--secondary_dark);
  transition: filter 0.2s;
}
nav.react-contextmenu .react-contextmenu-item:hover {
  filter: brightness(120%);
  cursor: pointer;
}

nav.react-contextmenu > * i {
  margin-right: 8px;
}

.react-contextmenu-item.react-contextmenu-item--disabled {
  pointer-events: none;
  opacity: 0.5;
}
.react-contextmenu-item.wrapper--disabled:hover {
  filter: brightness(1);
}

/* -----------------------------------------------------
                Material UI Table
--------------------------------------------------------*/

.MuiTableCell-root,
.MuiTableCell-body {
  color: var(--text) !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  border: 0 !important;
  letter-spacing: 0em;
}

/* -----------------------------------------------------
                    List-Input
--------------------------------------------------------*/

.list-input input {
  background-color: var(--secondary_dark);
  border: 0;
  margin: -8px -5px;
  padding: 6px 12px;
  width: calc(100% + 10px);
}

.list-input {
  padding: 8px 5px;
  display: flex;
  flex-direction: column;
  color: var(--text);
  cursor: text;
}

.list-input .list-input-content {
  padding-top: 1px;
  margin: 0 -5px -8px;
}

.list-input .list-input-content span.secondary {
  float: right;
  margin-right: 12px;
}

.list-input .list-input-content.horizontal {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}
.list-input .list-input-content.horizontal .list-item {
  border-right: 3px solid var(--secondary);
  display: flex;
  flex-direction: row-reverse;
}
.list-input .list-input-content.horizontal .list-item i {
  margin-left: 5px;
}

.list-item,
.list-item .list-item i {
  cursor: pointer;
}

.list-input .list-item {
  background-color: var(--background);
  padding: 5px 8px;
  flex-direction: row;
  border-top: 3px solid var(--secondary);
}
.list-input .list-item:hover {
  cursor: initial;
}

.list-input .list-item i {
  padding: 4px;
  float: right;
  transition: filter 0.2s, padding 0.2s, font-size 0.2s;
}
.list-input .list-item i:hover {
  cursor: pointer;
  filter: brightness(var(--bright));
  font-size: 18px;
  padding: 1px 0 0 5px;
}
.list-input .list-item span {
  font-size: 14px;
  cursor: default;
}

.list-input .control {
  display: flex;
}
.list-input .control .btn {
  margin-top: -8px;
  margin-right: -4px;
  margin-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.list-input .control .secondary {
  margin-left: 10px;
}

/* -----------------------------------------------------
                    Cards
--------------------------------------------------------*/

.page .card {
  background-color: var(--secondary);
  box-shadow: 0px 0px 11px 0.57px #00000061;
  border-radius: 0px;
  padding: 10px;
  width: 100%;
}

.page .card .heading {
  width: 100%;
  margin-top: -10px;
  padding: 10px;
  border: solid 0px #415464;
  border-bottom-width: 2px;
}

.console .header .btn {
  font-size: 15px;
}

.heading {
  position: relative;
}

.heading .actions > * {
  height: 32px;
}

.heading .header {
  font-size: 18px;
}

.heading .header,
.heading .sub-header {
  font-weight: normal;
  font-style: normal;
  display: inline;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
}

.heading .sub-header {
  font-size: 14px;
  vertical-align: super;
  padding: 8px 0 8px 8px;
}

.page .card .body {
  position: relative;
  overflow: visible;
  flex: 1 1;
  display: flex;
  padding: 10px;
}

.page .card .body span {
  font-size: 15px;
  margin: 14px;
}

/* -----------------------------------------------------
                    Menu Sorting
--------------------------------------------------------*/

.menu-sorting p {
  display: contents;
}

.menu-sorting i,
.menu-sorting .right {
  font-size: 12px;
}

.menu-sorting {
  color: var(--text);
}
.menu-sorting span {
  font-size: 12px !important;
}

.menu-sorting .sort-item {
  margin-left: 7px;
}

.menu-sorting .sort-item:hover,
.menu-sorting p {
  filter: brightness(var(--dim));
  cursor: pointer;
}

.menu-sorting .sort-item:hover {
  text-decoration: underline;
}

.menu-sorting .sort-item.active {
  color: var(--highlight_1);
  font-weight: 600;
  margin-right: 4px;
}

/* -----------------------------------------------------
                    MUI Pickers
--------------------------------------------------------*/

.MuiPaper-root.MuiPickersPopper-paper,
.MuiPaper-root.MuiDialog-paper {
  background-color: var(--secondary);
  color: var(--text);
  border-radius: 0;
  margin: 20px 0;
  box-shadow: 0px 2px 8px 0.44px #00000063;
}

.MuiPaper-root button.MuiButtonBase-root svg,
.MuiPaper-root .MuiPickersToolbar-root span {
  fill: var(--text);
  color: var(--text);
}

.MuiPaper-root .MuiTab-root.Mui-selected svg {
  fill: var(--primary_dark);
  color: var(--primary_dark);
}

span.MuiTabs-indicator {
  background-color: var(--primary_dark);
}

.MuiPaper-root .MuiDialogActions-root button.MuiButtonBase-root {
  background-color: var(--primary_dark);
  color: var(--text);
  font-size: 12px;
  border-radius: 0;
  padding: 6px 12px;
}

.MuiPaper-root .MuiDialogActions-root button.MuiButtonBase-root:first-child {
  background-color: var(--secondary_dark);
}

.MuiPickersCalendarHeader-labelContainer {
  font-size: 1.35rem !important;
  /*pointer-events: none;  /* Disabled since date picker cannot exceed 7 days */
}

.MuiPickersCalendarHeader-labelContainer svg {
  fill: var(--text);
  /* opacity: 0; */
}

.MuiPaper-root .MuiDayCalendar-header .MuiTypography-caption {
  color: color-mix(in srgb, var(--text) 60%, #00000000);
}

.MuiPaper-root .MuiDayCalendar-monthContainer .MuiButtonBase-root.MuiPickersDay-root {
  color: var(--text);
  font-size: 0.75em;
}

.MuiPaper-root .MuiDayCalendar-monthContainer .Mui-disabled.MuiPickersDay-root {
  color: color-mix(in srgb, var(--text) 25%, var(--secondary));
}

.MuiPaper-root .MuiDayCalendar-monthContainer .Mui-selected {
  background-color: var(--primary_dark);
}

.MuiPaper-root .MuiDayCalendar-monthContainer .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
  border-color: color-mix(in srgb, var(--text) 40%, #00000000);
}
