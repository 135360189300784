/* -----------------------------------------------------
                Custom Dropdown selector
--------------------------------------------------------*/

.drop-down {
  position: relative;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.drop-down .r {
  background-color: var(--background);
  padding: 10px;
  padding-right: 15px;
  transition: filter 200ms;
}

.drop-down .r .val {
  display: inline;
  font-weight: regular;
  font-style: normal;
  text-align: left;
}

.drop-down .r .val.empty {
  font-style: italic;
  color: var(--placeholder);
}

.drop-down i {
  font-size: 20px;
  float: right;
  transition: transform 200ms;
}

.drop-down.open i {
  transform: rotate(180deg);
}

.drop-down .drop {
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0.44px #00000063;
}

.drop-down.open {
  z-index: 4;
}

.drop-down .drop.reverse {
  bottom: 100%;
  top: unset;
}

.drop-down .drop .op {
  background-color: var(--secondary);
  padding: 10px;
}
.drop-down .drop .op.inactive:hover,
.drop-down .r:hover {
  filter: brightness(var(--bright));
  cursor: pointer;
}

.drop-down .drop .op.active {
  background-color: var(--highlight_1);
}
.drop-down .drop .op.active:hover {
  cursor: default;
}

.drop-clear {
  background-color: #00000000;
  position: fixed;
  inset: 0;
  z-index: 2;
}
