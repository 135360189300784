/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.sidepanel {
  width: 70px;
  position: fixed;
  z-index: 2;
  height: 100%;
  overflow-x: unset;
  transition: 0.5s;
  /* box-shadow: 5px 5px 27px 0px #0000007d; */
  background-color: var(--primary_dark);
}

.sidepanel > *:first-child {
  margin-top: 10px;
}

.sidepanel.open {
  width: 220px;
  box-shadow: 0 0 20px 3px #00000078;
}

.sidepanel .bottom {
  width: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.5s;
}
.sidepanel .bottom.open {
  width: 220px;
}

[class^="btn-"] {
  position: relative;
  display: block;
  overflow: hidden;
}

.btn-0 {
  color: white;
  background-color: var(--primary_dark);
  text-decoration: none;
  margin: 5px 0;
  padding: 23px;
  z-index: 2;
  display: flex;
  align-items: center;

  transition: border-left 550ms, padding-left 550ms, filter 200ms;
}

.btn-0 i,
.btn-0 img {
  width: 24px;
  min-width: 24px;
  margin-right: 16px;
}

.btn-0 img {
  height: 24px;
}

.btn-0:hover {
  cursor: default;
}

.link .btn-0:hover {
  cursor: pointer;
  filter: brightness(var(--dim));
}

.btn-0 p,
a.link {
  overflow-x: hidden;
  white-space: nowrap;
  margin: 0;
  font-size: 17px;
  text-decoration: none;
  /* color:var(--text); */
}

.btn-0 .link:hover {
  text-decoration: none;
  color: var(--text);
}

.btn-0.active {
  border-left: solid 10px var(--primary);
  padding-left: 11px;
}
.btn-0.btn-0.active:hover {
  filter: brightness(1);
  cursor: default;
}

*[data-demo="true"] .btn-0 {
  background-color: transparent;
  color: #0000007a;
}
