/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */

.topnav {
  position: relative;
  padding: 14px 95px;
  height: var(--nav_height);
  background-color: var(--secondary);
  box-shadow: 5px 5px 15px 0px #00000050;
  z-index: 10;
}

.topnav.home-nav {
  background-color: var(--home_bg);
}

.topnav .logo {
  height: 22px;
  width: auto;
}

.topnav .logo :hover {
  cursor: pointer;
}

.topnav a.nav {
  float: right;
  color: var(--text);
  text-align: center;
  padding: 0 8.652px;
  text-decoration: none;
  font-size: 14px;
}

.topnav a:hover {
  filter: brightness(var(--bright));
  color: var(--primary_dark);
  /* color: var(--text); */
}

.topnav a.active {
  background-color: var(--primary);
  color: var(--text);
}

.topnav .logo .st0 {
  fill: var(--text);
}
