/* -----------------------------------------------------
                    Search Input
--------------------------------------------------------*/

.search-bar {
  position: relative;
  color: var(--text);
}

.search-bar input {
  background-color: var(--background);
  -webkit-text-fill-color: var(--text);
  color: var(--text);
  border: 0;
  border-radius: 100px;
  padding: 6px 12px;
  font-size: 1.5rem;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  position: absolute;
}
.search-bar .search-ico {
  padding: 4px 16px 4px 2px;
  right: -8px;
}

.search-bar .search-ico.non-empty .ico-body {
  cursor: pointer;
}

.search-bar .search-ico.actions > * {
  margin-right: 5px;
}

.search-ico.empty .ico-body,
.search-ico.non-empty .ico-body:hover {
  filter: brightness(var(--dim));
}

.search-bar .search-ico {
  position: absolute;
  flex-direction: row-reverse;
  transition: width 0.2s;
}

.search-bar p {
  left: 8px;
}

.search-bar .search-ico .ico-body {
  padding: 5px;
  margin-left: -2px;
}

.search-bar .search-ico.icon-hidden {
  width: 0px !important;
}
