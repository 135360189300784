.landing {
  position: relative;
}

.page-area {
  overflow-y: auto;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: var(--nav_height);
}

.padding {
  content: "";
  display: block;
  height: 180px;
}

footer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 230px;
  background-color: var(--home_bg);
  /* box-shadow: 0 0 40px 4px #0000007a;  */
}

footer > * {
  margin: 0 auto 20px;
  width: 83%;
}

footer .separator {
  background-color: var(--tertiary);
  margin: 40px auto;
  height: 1px;
  width: 46%;
}

footer .actions a {
  color: var(--text);
  font-weight: 600;
  transition: 0.2s;
  padding-right: 1em;
  /* margin: 0 2% 0 0; */
}
footer .actions a:hover {
  opacity: 0.5;
  text-decoration: none;
}
footer .actions {
  align-items: stretch; /* Default */
  /* justify-content: space-between; */
}

footer .social-row {
  margin-bottom: 30px;
}
footer .social {
  margin-right: 12px;
}
footer .social i {
  font-size: 18px;
  color: var(--text);
}
footer .social svg .st0 {
  fill: var(--text);
}
footer .social svg {
  width: 18px;
  height: auto;
  margin: -2px 0;
}
