.console .org-info .field,
.console .org-info .value {
  display: flex;
  flex-direction: row;
}

.console .org-info {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  width: 100%;
  grid-template-areas: "general templates";
}

.console .org-info .templates {
  grid-area: templates;
}
.console .org-info .general {
  grid-area: general;
}

.console .org-info .subsection:first-child {
  margin-right: 8px;
}
.console .org-info .subsection:last-child {
  margin-left: 8px;
}

.console .org-info .field {
  margin-bottom: 12px;
  position: relative;
  flex-grow: 1;
}

.console .org-info .field label {
  vertical-align: middle;
}

.console .subsection.templates .value {
  flex-grow: 0;
}
.console .subsection.templates label {
  flex-grow: 2;
}

.console .org-info .value {
  flex-grow: 2;
  flex-direction: row-reverse;
}

.console .org-info .field input {
  background-color: var(--background_dark);
  color: var(--font);
  border: 0;
  padding: 8px 12px;
  margin: -8px 0px -8px -12px;
}

.console .org-info .field input,
.console .org-info .field .input {
  min-width: 300px;
  text-align: right;
}

.console .org-info .input {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
  text-wrap: nowrap;
}

.console .org-info .input.empty {
  filter: brightness(var(--dim));
  font-style: italic;
}

.console .org-info .subsection {
  position: relative;
}
.console .org-info .field .value .error {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--error);
}

.console .title .success-toast {
  overflow: hidden;
  text-overflow: clip;
  text-wrap: nowrap;
  text-align: right;
  font-size: medium;
  width: 0px;
  color: var(--success);
  transition: width 0.2s;
}
.console .title .success-toast.shown {
  width: 200px;
}
.console .title .success-toast:hover {
  cursor: default;
  filter: none;
}

.modal-pnl .error-area {
  position: relative;
  color: var(--error);
  margin-top: -5px;
  margin-bottom: 8px;
}
.modal-pnl .error-area.drodown-error {
  margin-bottom: 0;
  margin-top: 5px;
}

.modal-pnl.modify-modal .modal-input.offset {
  margin-bottom: 16px;
}

.modal-pnl.template-modal .template-url {
  display: flex;
  flex-direction: row-reverse;
}
.modal-pnl.template-modal .template-url i.error,
.modal-pnl.template-modal .template-url i.generate-spin {
  margin-right: 8px;
  flex-grow: 1;
  padding-top: 10px;
}
.modal-pnl.template-modal .template-url i.generate-spin {
  color: var(--secondary);
}
.modal-pnl.template-modal .template-url .btn {
  flex-grow: 2;
  width: 100em;
  float: right;
}
