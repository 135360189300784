/* -----------------------------------------------------
                    Main
--------------------------------------------------------*/

main.console {
  position: absolute;
  font-family: Montserrat;
  color: var(--text);
  padding: 0 !important;
  flex-direction: row;
  display: flex;
  flex: 1 1 0%;
  width: calc(100% - 70px);
}

/* .console .page {
  background-color: var(--console_transparency);
} */

.console .page > * {
  margin-bottom: 14px;
}
.console .page > *:last-child {
  margin-bottom: 0;
}

.console .subheading {
  padding: 10px;
  background-color: var(--background_dark);
  border-bottom: 5px solid var(--primary_dark);
  margin-bottom: 12px;
}

/* -----------------------------------------------------
                    Tabs
--------------------------------------------------------*/

.console .tabs {
  width: 247px;
  margin-top: 13px;
  flex-direction: column;
  display: flex;
  margin-bottom: 2px;
}

.console .group-stack {
  position: relative;
  flex: 1 1 0%;
  margin-bottom: 11px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.console .group {
  top: 0px;
  left: 0px;
  width: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.console .border {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: solid 0 #415464;
  border-right-width: 3px;
  pointer-events: none;
}

.console .tab {
  display: flex;
  background-color: var(--secondary);
  vertical-align: middle;
  padding: 18px;
  font-size: 16px;
  margin-bottom: 8px;
  transition: filter 200ms;
}
.console .tab.active {
  background-color: var(--highlight_1);
  background: linear-gradient(250deg, var(--highlight_1) 0%, var(--highlight_1) 30%, var(--primary_dark) 100%);
}
.console .tab.inactive:hover {
  filter: brightness(var(--bright));
  cursor: pointer;
}
.console .tab.active:hover {
  cursor: default;
}

.console .tab:disabled,
.console .tab[disabled] {
  filter: brightness(50%);
  pointer-events: none;
  cursor: default;
}

.console .tab i {
  font-size: 20px;
  margin-right: 12px;
}

/* -----------------------------------------------------
                    Network List
--------------------------------------------------------*/

.console .page .network-list .body,
.console .page .user-list .body {
  flex-direction: column;
}

.console .page table .indicator {
  position: absolute;
  left: 5px;
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px;
  transform: translate(0, -10%);
  -webkit-transform: translate(0, -10%);
}
.console .page table .indicator i {
  transition: transform 0.2s;
}
.console .page table .open.indicator i {
  transform: rotate(90deg);
}

.console .page .network-list {
  align-self: stretch;
  padding: 10px;
  flex-direction: column;
  display: flex;
}

.console .page .telemetry table {
  width: calc(70% - 2.5rem);
}

.console .page span.indent {
  margin: 0 10px 0 0 !important;
}

.console .page .network-list table .btn {
  padding: 10px;
  line-height: 1;
  margin-top: -10px;
  margin-bottom: -7px;
}

.console .network-list .dash-table .ctx-ind .ctx-menu-btn {
  top: 4px;
}

.console .network-list,
.console .user-list.card {
  margin-bottom: 0;
}

.console .user-list.card {
  display: flex;
  flex-direction: column;
}

.modal-pnl.user-modal .drop-down .r {
  min-width: 165px;
}

.modal-pnl.network-modal .modal-error {
  margin-top: 0px;
  margin-bottom: -15px;
}

/* -----------------------------------------------------
                    Settings 
--------------------------------------------------------*/

.console .settings .body {
  flex-direction: row !important;
  flex: 1 1 0%;
  display: flex;
}

.console .settings .rc {
  flex: 0.87 1 0%;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.console .rc > *:last-child {
  margin-bottom: 0;
}

.console .settings .lc {
  flex: 1.13 1 0%;
}

.console .lc {
  padding-right: 14px;
}

.console .lr,
.console .rc {
  flex: 1 1 0%;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.console .op-check {
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  display: flex;
}

.console .op-check .check-box {
  margin-right: 14px;
  width: 20px;
  height: 20px;
}

.console .op-drop,
.console .op-check {
  margin-bottom: 15px;
}

.console .op-drop .drop-down,
.console .react-input {
  margin-top: 8px;
}

.console .op-tog {
  margin-bottom: 14px;
  display: flex;
}
.console .op-tog .toggle {
  margin-right: 17px;
}
.console .op-tog .toggle.static > * {
  cursor: default !important;
  pointer-events: none;
}
.console .op-tog .desc {
  margin-top: 5px;
}

/* -----------------------------------------------------
                    Account info 
--------------------------------------------------------*/

.console .prof-pic {
  color: var(--text);
  width: 283px;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.console .prof-pic p {
  padding: 17px;
  padding-top: 0;
}

.console .image-region {
  flex: 1 1 0%;
  flex-direction: column;
  align-self: stretch;
  margin: 0 28px;
  padding: 0px;
  position: relative;
  display: flex;
  max-height: 205px;
  max-width: 205px;
}

.console .image {
  width: 231px;
  height: 100%;
  align-self: center;
  border-radius: 100px;
  object-fit: cover;
}

.console .color-bg {
  background-color: #ed6796;
  border-radius: 100%;
  display: flow-root;
  height: 100%;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.console .color-bg:hover {
  cursor: default;
}

.console .color-bg > * {
  transform: translate(0%, 80%);
}

.console .fields {
  flex: 1 1 0%;
  flex-direction: column;
  align-self: stretch;
  margin-right: 10%;
  max-width: 50%;
  display: flex;
}

.console .fields > * {
  flex-direction: row;
  align-self: stretch;
  margin-bottom: 20px;
  display: flex;
}
.console .fields > *:last-child {
  margin-bottom: 0;
}

.console .fields > * > * {
  width: 100%;
}

.console .fields > * > *:first-child {
  margin-right: 8.5px;
}
.console .fields > * > *:last-child {
  margin-left: 8.5px;
}

.console .fields .input-email {
  margin: 0;
}

.console .mfa .horiz-group .react-input {
  background-color: transparent;
}
.console .security button {
  color: (--text) !important;
}
.console .security .horiz-group .btn {
  margin-left: 17px;
  margin-top: 8px;
  height: 44px;
  padding: 12px 23px;
  color: var(--text);
}
.console .security form .actions > *:first-child {
  padding-top: 12px;
}

.horiz-group {
  flex-direction: row;
  align-self: stretch;
  display: flex;
}

.console .pass input,
.console .pass .actions {
  height: 44px;
}
.console .pass input {
  font-size: 14px;
  border-radius: 0;
  border: 0;
  font-size: 1.5rem;
  background-color: var(--background);
  -webkit-text-fill-color: var(--text);
  color: var(--text) !important;
  margin-bottom: 8px;
  /* box-shadow: inset 0 0 0 1000px var(--background) !important; */
  position: relative;
}
.console .pass form {
  margin-top: 8px;
}
.console .pass .actions {
  margin-top: 8px;
}
.console form .row.password .toggler {
  padding: 13px 16px;
}

.console .personal-info .body {
  flex: 1 1 0%;
  flex-direction: row;
  align-self: stretch;
  display: flex;
  transition: height 1s;
}

.console .react-input {
  height: 44px;
  font-size: 14px;
  flex: 1 1 0%;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  padding: 10px;
  vertical-align: middle;
  display: flex;
  cursor: text;
  text-overflow: ellipsis;
}

.console .react-input.invalid {
  border: 1px solid var(--error);
}

.console .field-error {
  color: var(--error);
}

.console .react-input.static {
  filter: brightness(var(--dim));
  background-color: var(--background);
  /* background-color: var(--secondary);
  border-bottom: 1px solid var(--text); */
  cursor: default;
  pointer-events: none;
}

.console .op-drop .drop-down .react-input {
  margin-top: 0;
  font-size: 15px;
}

.chg-img {
  position: relative;
  display: block;
  height: 100%;
}
.console .chg-btn i,
.console .chg-btn input.fake {
  font-size: 24px;
}
.console .chg-btn i:hover {
  cursor: pointer;
}

.chg-img .img {
  padding-top: 100%;
}

.chg-img .chg-btn {
  position: absolute;
  transition: 0.25s;
  padding: 8px 12px;
  background-color: #5a5a5ad3;
  color: #ffffffd7;
  border-radius: 1000px;
  top: 10px;
  left: 10px;
  transition: 0.25s;
}

.chg-img .chg-btn:hover {
  cursor: pointer;
  padding: 12px 16px;
}

.chg-img .fake {
  opacity: 0;
  z-index: 2;
  left: -10px;
  top: -10px;
  bottom: -261px;
  position: absolute;
}

.chg-img .fake :hover {
  cursor: pointer;
}

.usability-text-size-slider {
  width: 200px;
  margin: 20px;
}

.usability .op-drop {
  min-width: 10em;
}

/* -----------------------------------------------------
                    Billing
--------------------------------------------------------*/

.console .billing .graph-container {
  width: 100%;
  margin-top: 12px;
}
.graph-container .chart-container {
  height: 21rem;
}

.console .billing .overlay {
  position: relative;
  background-color: #0000005e;
  border: 1px solid #0000008a;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.console .billing .overlay .cd {
  position: absolute;
  font-size: 32px;
  transform: translateY(-50%);
}

.console .billing .jumbo {
  width: 100%;
  /* height: 14rem; */
  /* display: flex; */
  align-items: center;
}

.console .billing .heading .btn.refresh {
  background-color: transparent;
  margin: 0;
}

.console .breakdown {
  margin-right: 2.5rem;
  width: 30%;
}

.console .overall-costs .actions {
  margin-top: -3px;
}

.console .billing .heading .drop-down {
  width: 140px;
  font-size: 14px;
}
.console .heading .drop-down .r {
  height: inherit;
}
.console .heading .drop-down .r i {
  font-size: 14px;
}
.console .heading .drop-down .r .val {
  position: absolute;
  top: 6px;
}

/* -----------------------------------------------------
                    Calendar Range Picker 
--------------------------------------------------------*/

.calendar-select {
  background-color: var(--background);
  margin: 0 -5px;
  text-overflow: ellipsis;
}

.calendar-sel-btn {
  padding: 5px 43px;
  cursor: pointer;
}

.calendar-select i.fas.fa-caret-down {
  position: absolute;
  right: 15px;
  top: 8px;
  transition: transform 200ms;
}

.calendar-select i.fas.fa-caret-down.open {
  transform: rotate(180deg);
}

.console .billing .overall-costs .btn.small {
  line-height: 1.7;
  padding: 4px 10px;
  margin: 0 -10px;
}

.console .billing .heading .drop-down.open {
  margin-right: -5px;
}

.calendar {
  opacity: 0;
  transition: opacity 0.2s;
}
.calendar.open {
  opacity: 1;
}
.calendar.closed {
  pointer-events: none;
}

.calendar .cover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.021);
  cursor: auto;
  z-index: 10;
  left: -70px;
  right: 0;
  top: calc(0 - var(--nav_height));
  bottom: 0;
  display: none;
}
.calendar .cover.open {
  display: block;
}

.console .rdrDateRangePickerWrapper {
  position: absolute;
  display: flex;
  z-index: 11;
  top: 100%;
  left: 0;
  transform: translate(-50%, 8px);
  background-color: var(--secondary);
  padding: 21px;
  box-shadow: 0 0 19px 1px #0000008a;
}

.console .rdrDefinedRangesWrapper {
  background-color: var(--secondary_dark);
  border-right-color: var(--secondary_dark);
  max-width: 115px;
  margin: -21px;
  margin-right: 21px;
  padding: 21px 0;
}

.console .rdrCalendarWrapper.rdrDateRangeWrapper,
.console .rdrDateDisplayWrapper {
  color: var(--text);
  background-color: transparent;
}

.console .rdrDateInput.rdrDateDisplayItem {
  background-color: var(--secondary_dark);
  color: var(--primary_dark);
  border-radius: 0;
}

.console .rdrDateDisplayItemActive input {
  color: var(--text);
}

.console .rdrMonthAndYearWrapper {
  padding-top: 0;
  margin-top: -10px;
}

.console .rdrStaticRangeLabel,
.console .rdrStaticRange {
  background-color: var(--secondary);
}

.console .rdrStaticRange {
  border-bottom: 1px solid var(--secondary_dark);
}

.console .rdrStaticRange:hover,
.console .rdrStaticRange span:hover {
  background-color: var(--secondary);
  filter: brightness(var(--bright));
}

.console .rdrStaticRange.rdrStaticRangeSelected {
  color: var(--text) !important;
}

.console .rdrStaticRange.rdrStaticRangeSelected,
.console .rdrStaticRange.rdrStaticRangeSelected span {
  background-color: var(--primary_dark);
  pointer-events: none;
  cursor: default;
}

.console .rdrInputRanges {
  display: none;
}

.console .rdrDay span.rdrDayNumber span {
  color: var(--text);
}

.console .rdrMonthAndYearPickers select {
  color: var(--text);
  opacity: 0.5;
  border-radius: 0px;
  width: 100%;
  background-color: var(--secondary_dark);
}
.console .rdrMonthAndYearPickers select:hover {
  background-color: var(--secondary);
  filter: brightness(var(--bright));
}

.console span.rdrMonthPicker {
  margin: 0;
  width: 100%;
  display: flex;
}

.console .rdrDayDisabled {
  background-color: transparent;
  opacity: 0.1;
}

.console span.rdrYearPicker {
  margin: 0;
  width: 58%;
  border-left: 1px solid var(--text);
}

.console button.rdrNextPrevButton {
  background-color: var(--primary_dark);
  border-radius: 0;
  height: 36px;
  color: var(--text);
}
.console button.rdrNextPrevButton:hover {
  filter: brightness(var(--bright));
}

.console .rdrNextButton {
  margin-left: 0;
}
.console .rdrNextButton i {
  border-color: transparent transparent transparent var(--text);
}

.console .rdrPprevButton {
  margin-right: 0;
}
.console .rdrPprevButton i {
  border-color: transparent var(--text) transparent transparent;
}

.console .rdrMonth {
  padding-bottom: 0;
}

/* -----------------------------------------------------
                    Other 
--------------------------------------------------------*/

.console .info {
  width: 30% !important;
  max-width: 350px;
}
.console .row2 {
  display: flex;
  flex-direction: row;
  height: 255px;
  align-self: stretch;
  margin-right: -18px;
  position: relative;
}

.console .card .info {
  flex: 0.22 1 0%;
}

.console .info .body,
.console .settings .body {
  padding: 15px;
}

.console .row2 > * {
  margin-right: 18px;
}

.console .chart-container canvas {
  max-height: 30rem;
}

.console .page .network-list .jumbo {
  margin: 10px;
}
#network-list tbody tr {
  cursor: default;
}

.ReactModalPortal button {
  color: var(--text) !important;
}

.modal-pnl .form-group .error {
  position: absolute;
  max-width: 25rem;
  right: 20px;
  margin-top: 2px;
  padding: 1rem;
  z-index: 10;
  font-size: 14px;
  /* text-align: center; */
  border: solid 1px #ff2a2a;
  background-color: #a02525d1;
  transform: translate(100%, 0);
}

@media only screen and (max-width: 1024px) {
  .console .fields {
    margin: 0;
  }
  .console .fields > * {
    flex-direction: column;
  }

  .console .fields > * > *:last-child {
    margin-left: 0px;
    margin-top: 8.5px;
  }
}

@media only screen and (max-width: 1304px) {
  .console .billing {
    display: flex;
    flex-direction: column;
  }

  .console .billing .third,
  .console .billing .full {
    padding-right: 0px;
  }
}

.modal-pnl.user-modal i.success {
  color: var(--success);
}
.modal-pnl.user-modal i.error {
  color: var(--error);
}

.modal-pnl.user-modal .result-entry {
  position: relative;
  display: flex;
  margin-bottom: 15px;
}
.modal-pnl.user-modal .result-entry:last-child {
  margin-bottom: -10px;
}
.modal-pnl.user-modal .result-entry .indicator {
  margin-right: 12px;
  width: 22px;
}
.modal-pnl.user-modal .result-entry .indicator .backing {
  position: absolute;
  inset: 0 0 0 0;
  font-size: 22px;
}
.modal-pnl.user-modal .result-entry .indicator .icon {
  padding: 4px 5px 5px 4px;
}
.modal-pnl.user-modal .result-entry .icon.fa-times::before {
  margin-left: 2px;
}
.modal-pnl.user-modal .result-entry span {
  flex-grow: 2;
}
