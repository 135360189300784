select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

/* -----------------------------------------------------
                      Global
--------------------------------------------------------*/

/* Soft easing between themes */
* {
  font-family: Montserrat;
  transition: color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
}

input[type="file"] {
  width: 100%;
}

html,
body {
  height: 100%;
}

html {
  overflow: hidden;
}

/* Some values can get overwritten when user's theme preferences have been loaded */
:root {
  --placeholder: #9394a1;
  --primary: #2f92b8; /* Auspicious Blue*/
  --primary_dark: #2878d3;
  /* --primary_dark_dim: #2878d324; */
  --secondary: #0e111b; /*#282c30*/
  --secondary_mid: #2f363d;
  --secondary_dark: #282c30;
  --tertiary: #dae0e7; /* Icicle */
  --highlight_1: #02838d; /* Patina Green */
  --highlight_2: #bb5b00; /* Xanthe Orange */
  --background: #191e2c;
  --background_dark: #0e111b;
  --background_gradient_first: #1d2336;
  --background_gradient_second: #0e111b;
  --text: #fff; /* Frost */
  --home_bg: #0e111b;
  --home_bg_dark: #232732;
  --list_transparency: #00000040;
  --list_transparency_hover: #0000001f;
  --table_header: #191e2c;
  --table_row_odd: #00000026;
  --list_transparency_dark: #282c308c;
  --console_transparency: #00000091;
  --error: #df0101;
  --error_transparency: #df01014d;
  --success: #089f4c;
  --succses_transparency: #089f4c4d;

  --bright: 125%;
  --dim: 80%;
  --disabled: 50%;

  --nav_height: 50px;
  --panel_transition: 350ms;
}
