/* ---------------------------------------------------
    INFO PANE STYLE
----------------------------------------------------- */

.info-list {
  position: fixed;
  right: 0;
  z-index: 5;
  width: 375px;
  margin-top: -30px;
  height: calc(100% - var(--nav_height));
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--text);
  transition: right var(--panel_transition);
  background-color: var(--secondary);
  box-shadow: 5px 5px 27px 0px #0000007d;
}

.info-list.collapsed {
  right: -375px;
}

.info-list .full {
  text-align: center;
  font-size: 30px;
}
.info-list .full i {
  margin-top: 50%;
}

.info-list > *:nth-child(2) {
  margin-top: 0;
}

.info-list .toggle {
  position: fixed;
  right: 375px;
  z-index: 1;
  font-size: 25px;
  padding: 8px 12px;
  background-color: var(--highlight_1);
  transition: filter 0.2s, right var(--panel_transition);
}
.info-list .toggle:hover {
  cursor: pointer;
  filter: brightness(var(--bright));
}
.info-list.collapsed .toggle {
  right: 0px;
}
.info-list .toggle.tog-hide {
  right: -40px !important;
}

.info-list .toggle i {
  transition: transform 0.2s;
}
.info-list .toggle i.face-right {
  transform: rotate(180deg);
}

.info-group {
  margin-top: -5px;
}
.info-list .info-group:first-child {
  margin-top: 0px;
}

.info-group .info-header {
  background-color: var(--highlight_1);
  color: white;
  padding: 10px 16px;
  font-size: 22px;
}

.info-group.overflow {
  margin-right: 11px;
}

.info-group.overflow .info-header {
  margin-right: -11px;
}

.info-group table {
  width: 100%;
}

.info-group table tr {
  /* padding: 15px; */
  border-bottom: 1px solid #5f6d7b;
  /* text-align: left;
    display: block; */
}

/* .info-group table tbody tr td:first-child {
    display: contents;
  } */

.info-group table tbody tr td:last-child {
  /* position: relative; */
  /* right: 0; */
  float: right;
  padding-right: 22px;
}

/* Pie Chart */
.info-group table tbody .piechart {
  display: flex;
  border: 0;
}

.info-group table tbody .piechart td:last-child {
  top: 0;
  width: 70%;
  padding: 50px;
}

.info-group table tbody .piechart img {
  width: 100%;
}

.info-group .legend {
  padding: 0 15px;
}

.info-group .legend tr {
  border: 0;
}
.info-group .legend tr:hover {
  filter: brightness(100%);
}

.info-group .legend .legend-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.info-group .legend .color-box {
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 8px;
}

.info-group .chart {
  position: relative;
}
.info-group .units {
  position: relative;
  top: -10px;
}
.info-group .units,
.info-group .units .legend-row,
.info-group .chart {
  background-color: transparent;
}
.info-group .units .full-item {
  padding: 0;
}
.info-group .units .legend-row,
.info-group .chart {
  border-bottom: 0;
}
.info-group .chart .field-item {
  width: 50px;
}

.info-group .legend .legend-item {
  display: inline-flex;
  flex-direction: row;
  font-size: 12px !important;
  max-width: 160px;
}

.info-group table tbody tr {
  background-color: var(--secondary);
  /* background-color: #00000001; */
}
.info-group table tbody tr:hover {
  filter: brightness(90%);
}
.flow-table thead tr:hover {
  filter: brightness(100%) !important;
}

.flow-table thead tr th {
  background-color: var(--secondary);
  cursor: pointer;
}
.flow-table thead tr th:hover {
  filter: brightness(90%);
}

.flow-table .status-box {
  margin: -12px 1px;
  padding: 2px 16px;
}

.info-group table tbody tr img {
  width: 50%;
  float: inherit;
  margin: -9px 0;
  height: auto;
}

.info-group table tr.table {
  display: contents;
}

.info-group .hidden-item {
  display: contents;
}

.info-group .field-item {
  display: inline-grid;
}

.info-group .full-item {
  width: 375px;
  position: relative;
}

.info-group .full-item .flow-table tbody tr {
  border: 0;
}
.info-group .full-item .flow-table tbody tr:nth-child(odd) {
  background-color: #0000000a;
}
.info-group .flow-title {
  text-align: center;
  padding: 6px;
  border-bottom: solid 1px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  width: 100%;
}

.flow-table th.MuiTableCell-head::before {
  border-left: solid 1px var(--text);
}

.info-list .info-group td span {
  display: flex;
  flex-direction: row-reverse;
}

.info-list .info-group td span .btn.risk-reset {
  margin: -16px 10px -16px -16px;
  padding: 16px 13px;
}
