/* Workspace Buttons */

.workspace .buttons {
  position: fixed;
  width: 73px;
  bottom: 45px;
  right: 375px;
  transition: right var(--panel_transition);
}

.workspace.expanded .buttons {
  right: 0;
}

.workspace .grp-btn {
  font-family: Ebrima, Arial, Helvetica, sans-serif !important;
  text-align: center;
  background-color: var(--secondary);
  font-weight: 600;
  font-size: 20px;
  border-radius: 10px;
  margin: 15px;
  padding: 8px;
}

.workspace .grp-btn:hover {
  cursor: pointer;
  background-color: var(--highlight_1);
}

.workspace .grp-btn[disabled] {
  filter: brightness(var(--dim));
}

.workspace .grp-btn[disabled]:hover {
  cursor: default;
  background-color: var(--secondary);
}

.chart .chart-container {
  width: 240px;
  height: 125px;
  /* margin: -5px 0; */
}

/* .chart canvas {
margin-top: -6px;
} */
