/* ---------------------------------------------------
    SYSTEM MAP NODE/EDGE AREA
----------------------------------------------------- */

.workspace {
  height: 100%;
  top: var(--nav_height);
  left: 70px;
  right: 375px;
  position: fixed;
  color: var(--text);
  transition: right var(--panel_transition);
  background-image: radial-gradient(var(--background_gradient_first), var(--background_gradient_second));
}

.workspace.expanded {
  right: 0;
}

.workspace .graph-message {
  top: 90px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  z-index: 1;
  position: relative;
  transform: translate(0, 50%);
}

.workspace .graph-message .body {
  position: absolute;
  margin: 0;
  padding: 20px;
  transform: translate(-50%, -50%);
  top: 43%;
  left: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--secondary);
  /* background-image: linear-gradient(13deg, var(--background), transparent); */
  box-shadow: 0 0 40px 2px #00000034;
  /* border: 1px solid var(--primary);*/
}

#visual {
  position: absolute;
  top: 40px;
  bottom: -40px;
  width: 100%;
  height: calc(100% - var(--nav_height) - 80px);
  z-index: -1;
}

#cy {
  height: 100%;
  width: 100%;
  display: block;
}

#cy div {
  height: 100% !important;
  overflow: unset !important;
}

.status-bar {
  position: fixed;
  bottom: 0;
  left: 70px;
  right: 375px;
  height: 40px;
  min-height: 40px;
  transition: right var(--panel_transition);
  background-color: var(--secondary);
}
.status-bar.collapsed {
  right: 0;
}
.status-bar .actions {
  margin-right: -5px;
  height: 100%;
}
.status-bar .actions > * {
  border-left: 1px solid var(--text);
  display: flex;
  padding: 5px 18px;
  margin: 5px 0;
}

.status-bar .zoom > *,
.status-bar .auto-sync > * {
  margin-right: 8px;
}
.status-bar .zoom:last-child,
.status-bar .auto-sync:last-child {
  margin-right: 0;
}

.workspace div#d3-graph {
  height: 100%;
}

.workspace .graph-options {
  position: absolute;
  top: 40px;
  width: 300px;
  background-color: var(--secondary_dark);
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff3d;
}
.workspace .graph-options .op {
  display: flex;
  justify-content: space-between;
  margin: 1px 6px;
  font-size: 12px;
}
.workspace .graph-options .btn {
  width: 100%;
  background-color: var(--background_dark);
}
.workspace .graph-options input {
  background-color: var(--background_dark);
  padding: 5px;
  border: 0;
}
.graph-options .drop-down {
  width: 153px;
}

.workspace .graph-legend-area {
  position: fixed;
  bottom: 10px;
  padding: 5px;
  display: flex;
  opacity: 0.9;
  background-color: var(--background_dark);
}
.workspace .graph-legend-title {
  fill: var(--text);
  font-size: 10px;
}
.workspace .graph-legend-area text {
  fill: var(--text);
  font-size: 9px;
}
.workspace #graph-node-size-legend circle {
  fill: gray;
}

.workspace .graph-legend-area svg.host-ico {
  height: 22px;
  width: auto;
}

.d3-svg-container {
  display: block; /*inline-block;*/
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* aspect ratio */
  overflow: hidden;
}
.d3-svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}
/* ---------------------------------------------------
    NETWORK LIST RIBBON
----------------------------------------------------- */

.ribbon {
  overflow: hidden;
  position: fixed;
  top: var(--nav_height);
  width: 100%;
  background-color: var(--background_dark);
  height: 40px;
  box-shadow: 1px -2px 20px 0px #00000073;
}

.ribbon .net-item {
  /* z-index: -1; */
  position: relative;
  max-height: 40px;
  min-height: 40px;
}

.ribbon .net-item:hover:not(.static) {
  background-color: var(--secondary);
  filter: brightness(var(--bright));
  cursor: pointer;
}
.ribbon .net-item.selected:hover {
  background-color: var(--highlight_1);
  filter: brightness(1);
  cursor: default;
}

/* .ribbon .net-item.static:hover {
  background-color: transparent;
  filter: brightness(1);
} */

.right-arrow {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 0;
  right: -17px;
  top: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 17px solid var(--secondary);
}
.ribbon .right-arrow.selected {
  background-color: transparent !important;
  border-left: 17px solid var(--highlight_1);
}

.ribbon .net-item,
.preface {
  float: left;
  height: 100%;
  box-shadow: 13px 0px 20px 0px #000000;
}

.ribbon .net-item,
.preface,
.net-entry {
  padding: 10px 16px 10px 31px;
}

.ribbon .net-item i {
  margin-left: 12px;
  padding-right: 8px;
}

.ribbon .selected {
  background-color: var(--highlight_1);
  color: white;
  z-index: -1;
}

.ribbon .center {
  text-align: center;
}

.ribbon .net-item .anchor {
  height: 100%;
  margin: -10px -25px;
  top: -21px;
  right: 0;
  z-index: -1;
}

.ribbon .dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 0px;
  max-height: 350px;
  overflow-y: hidden;
  z-index: 50;
  background-color: var(--secondary);
  transition: 0.2s;
}

.ribbon .dropdown.open {
  overflow-y: auto;
  height: auto;
}
.ribbon .net-item .backing {
  position: absolute;
  background-color: var(--secondary);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
}

.ribbon .net-item .net-entry {
  background-color: var(--secondary);
  color: var(--text);
}

.ribbon .net-item .net-entry:hover,
.ribbon .net-item .backing:hover {
  filter: brightness(var(--bright));
  cursor: pointer;
}

.ribbon .net-entry .anchor .selected:hover {
  filter: brightness(1);
  cursor: default;
}

.ribbon .net-item .net-entry {
  position: relative;
  background-color: var(--secondary_mid);
  transition: 0.2s;
}

.no-select:hover {
  filter: brightness(1);
  cursor: default;
}

.floating-filter {
  position: absolute;
  bottom: 60px;
  right: 0px;
  z-index: 10;
}

/**
dat.gui
**/
.dg.ac {
  top: 100px;
  font-family: Montserrat;
}

.dg .c select {
  color: black;
}

.dg.main {
  width: 300px;
}
.dg.main .close-button.close-bottom {
  width: 300px;
}
