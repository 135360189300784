/* -----------------------------------------------------
                    Metrics header
--------------------------------------------------------*/

.metrics {
  display: flex;
}

.metrics > * {
  background-color: var(--secondary);
  margin-right: 20px;
  box-shadow: 0px 3px 8px 0px #0000009e;
  display: flex;
}

.metrics .spark {
  border-bottom: 8px solid;
}

.metrics .spark .token {
  height: 100%;
  display: flex;
  text-align: center;
}

.metrics .spark .token .bed {
  margin: auto;
  padding: 12px;
  font-size: 20px;
  padding-bottom: 0;
}

.metrics .head {
  padding: 12px 20px 0;
}

.metrics .metric {
  position: relative;
  font-size: 42px;
  font-weight: 800;
  text-align: center;
  padding: 0 20px 5px 0;
}

.metrics .metric span {
  position: absolute;
  top: 7px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 4px;
}

.metrics .action {
  padding: 12px;
  padding-left: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.metrics .action .flow {
  display: grid;
}

.metrics .action .flow .btn {
  margin-top: 8px;
}

.metrics .due {
  padding: 0 25px;
}

.metrics .help {
  background-color: transparent;
  box-shadow: none;
  flex-direction: column-reverse;
  font-size: 18px;
}

.title .help > * {
  /* margin-top: 12px; */
  margin-left: 12px;
}
.title .help > *:hover {
  filter: brightness(var(--dim));
  cursor: pointer;
}
.title .help i.fas.fa-question-circle:hover {
  cursor: help;
}
.title .help i.fas.fa-sync {
  padding: 4px 10px;
  margin: 0;
}
.title .help .btn i {
  margin-right: 8px;
}
/* .title .help .btn {
  margin-top: -3px;
} */

.title .warning {
  color: var(--error);
  font-size: medium;
  padding-top: 4px;
}

.title .help {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: x-large;
  border-radius: 0 !important;
}

/* -----------------------------------------------------
                    Dashboard body
--------------------------------------------------------*/

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.columns.locked {
  grid-template-columns: 1fr;
}

.columns .full {
  grid-area: 1 / 1 / 4 / 4;
  padding-right: 17px;
}

.columns .full .card {
  margin-bottom: 14px;
}

.columns .third {
  grid-area: 1 / 4 / 4 / 5;
}

/* -----------------------------------------------------
                    Dashboard Table
--------------------------------------------------------*/

table.dash-table,
table.dash-table-empty {
  width: 100%;
}

.dash-table-empty {
  background-color: var(--table_row_odd);
}
table.dash-table tbody tr:nth-child(even) {
  background-color: #0000000f;
}
.dash-table tbody tr:hover {
  /* filter: brightness(var(--bright)); */
  background-color: #535b664d !important;
  cursor: pointer;
}

.dash-table-empty {
  padding: 15px 30px;
  position: relative;
}

table.dash-table td {
  padding: 15px 30px;
  position: relative;
}

table.dash-table thead {
  background-color: var(--secondary);
}
table.dash-table thead.secondary > *,
table.dash-table thead.secondary tr > * {
  height: 0px !important;
  padding: 0px !important;
  font-size: 0;
}
.dash-table thead.secondary .check-box {
  display: none;
}

/* div#network-list:first-child{
  margin-bottom: 20px;
} */

table.dash-table thead th {
  padding: 10px 30px;
  background-color: var(--table_header);
  position: relative;
}

table.dash-table thead th:not(:first-child):before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 25%;
  height: 50%;
  border-left: 1px solid var(--tertiary);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

table.dash-table thead th:not(:first-child):hover::before {
  cursor: pointer;
}

table.dash-table tbody {
  /* display: grid;
  height:250px; */
  width: 100%;
}

.dash-table tbody tr {
  width: calc(100% + 18px);
}

.dash-table .ctx-ind .ctx-menu-btn {
  position: absolute;
  right: 0;
  padding: 10px;
  padding-right: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.dash-table .ctx-ind .ctx-menu-btn:hover {
  cursor: pointer;
}
