.copy-field {
  cursor: pointer;
  background-color: 1px;
}

.copy-field i {
  margin-left: 8px;
  transition: filter 0.2s, transform 0.2s;
}

.copy-field:hover i {
  filter: brightness(var(--bright));
  transform: scale(1.2);
}
