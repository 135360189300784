@media all and (min-width: 480px) {
  .landing-splash {
    background-image: url(./home_splash.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    z-index: -100;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    padding: 60px 0;

    font-family: "Montserrat", sans-serif;
  }

  .home .btn {
    background-color: var(--highlight_2) !important;
    color: var(--text) !important;
  }

  .home .btn.ignore {
    background-color: var(--primary);
  }

  .home .body {
    /* max-width: 400px; */
    width: 40rem;
    position: relative;
    margin: 120px auto;
    background-color: var(--home_bg);
    box-shadow: 0 0 16px 2px #101010;
  }

  /* -----------------------------------------------------
                        Header
  --------------------------------------------------------*/

  .landing form .logo {
    background-color: var(--home_bg_dark);
    padding: 18px 20px;
  }

  .landing form .logo img {
    width: 55px;
    height: 100%;
  }

  .landing form .logo span {
    margin-left: 20px;
    font-size: 2.75rem;
    color: #b7bfc6;
    vertical-align: middle;
  }

  .landing form .logo a:hover {
    text-decoration: none;
  }

  /* -----------------------------------------------------
                        Form
  --------------------------------------------------------*/

  .home form {
    padding: 0 50px 5px;
  }

  .home form p {
    padding: 6px 0 10px;
  }

  .home form > * {
    margin-bottom: 20px;
  }

  .home form h3 {
    text-align: center;
    color: #b7bfc6;
    padding: 20px 10px;
    margin: 0;
  }

  .home form .sep-h {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    padding: 10px 0;
  }

  .home form input.form-control,
  .home form input.form-control:-webkit-autofill {
    border-radius: 0;
    border: 0;
    font-size: 1.5rem;
    background-color: var(--home_bg_dark);
    -webkit-text-fill-color: var(--text);
    color: var(--text) !important;
    box-shadow: inset 0 0 0 1000px var(--home_bg_dark) !important;
    position: relative;
  }

  .home form .error {
    position: absolute;
    max-width: 25rem;
    right: 20px;
    margin-top: 2px;
    z-index: 10;
    font-size: 14px;
    transform: translate(100%, 0);
  }

  .home form .error,
  .home form .form-err {
    padding: 1rem;
    border: solid 1px #ff2a2a;
    background-color: #a02525d1;
  }

  .home form input.form-control::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
  .home form input.form-control:-ms-input-placeholder, /* Internet Explorer 10-11 */
  .home form input.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #808589;
  }

  .home form input.form-control:focus {
    border-radius: 0;
    border: solid 2px #42596f;
  }

  /* Chrome, Safari, Edge, Opera */
  .home form input::-webkit-inner-spin-button,
  .home form input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .home form input[type="number"] {
    -moz-appearance: textfield;
  }

  .home form .chk-box {
    text-align: center;
    vertical-align: middle;
  }
  .home .login form .chk-box {
    margin-bottom: -20px;
  }
  .home .registration form .chk-box {
    margin-top: -10px;
  }
  .home form input.rc-checkbox-input {
    margin-right: 15px;
  }
  .home form .chk-box:hover,
  .home form a:hover {
    filter: brightness(var(--bright));
    cursor: pointer;
  }
  .home form a:hover,
  .home form a span:hover {
    color: var(--highlight_2);
  }
  .home form .chk-box,
  .home form a {
    transition: filter 200ms;
  }

  .home form a,
  .home form span {
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    font-size: 1.75rem;
    color: #b7bfc6;
  }

  .home form a {
    display: block;
  }

  .home form .options {
    position: relative;
    display: inline-flex;
    transform: translate(-50%, 0%);
    left: 50%;
  }
  .home form .options > * {
    margin-right: 14px;
  }
  .home form .options > *:last-child {
    margin-right: 0;
  }

  .error li.err {
    margin-left: 17px;
  }

  .error li.err.no-pin {
    display: contents;
  }

  .ResetPassword .success {
    max-width: 400px;
  }

  .home .registration.body {
    width: 46rem;
  }

  .home .registration form p a {
    display: contents;
    font-size: 14px;
    text-decoration: underline;
  }
}

/* -----------------------------------------------------
                      Miscellaneous
--------------------------------------------------------*/

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.landing .success {
  margin: 0 auto;
  padding: 27px;
  font-size: 16px;
  text-align: center;
}
.landing .success i {
  font-size: 30px;
  margin-bottom: 30px;
}
.landing .success a {
  color: var(--highlight_2);
}
.landing .success a:hover {
  text-decoration: none;
}

.ChangePassword .LoaderButton:last-child {
  margin-top: 15px;
}

.home form p.desc {
  margin-bottom: 0px;
}
.home form p.desc i {
  margin-top: 5px;
  margin-right: 6px;
  cursor: help;
}

.home .drop-down .r,
.home .list-input input,
.home .list-input .list-item {
  background-color: var(--home_bg_dark);
}

.home .op.inactive {
  background-color: var(--home_bg);
}

.home .list-input .list-item {
  border-color: var(--home_bg);
}

.home form .list-input,
.home form .drop-down {
  margin-bottom: 10px;
}

.home .stages {
  display: flex;
  position: absolute;
  justify-items: stretch;
  top: -56px;
  width: 100%;
}

.home .stages .stage-tab {
  background-color: var(--home_bg_dark);
  padding: 8px 23px;
  margin: 0 5px;
  min-width: calc(50% - 20px);
  position: relative;
  cursor: pointer;
}

.home .stages .stage-tab.active {
  cursor: default;
  background-color: var(--highlight_2);
}

.home .stages .stage-tab.invalid {
  cursor: default;
  filter: saturate(50%);
}

.home .registration .actions.primary {
  margin: 0 -5px 10px;
}

.home .actions .btn {
  margin-top: 0px;
}

.home .registration .actions {
  margin-bottom: 30px;
}

.home .stages .icon {
  display: flex;
}

.home .stages .icon svg {
  min-width: 29px;
  margin-right: 12px;
}

.home .stages .icon svg .st0 {
  fill: var(--text);
}

.home .recaptcha-wrapper > div > div > div {
  margin: auto;
}

.rc-anchor-error-msg-container {
  display: none;
}
