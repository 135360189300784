/* ============= Custom Checkbox ============= */

.check-cont {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-cont:hover input ~ .check-box {
  filter: brightness(var(--bright));
}

.check-box {
  display: flex;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin: 0;
  background-color: var(--check_box);
  border: 1px solid var(--check_box_dark);
  justify-content: center;
  color: var(--text);
  padding: 0 0 2px;
}
.check-box:hover {
  cursor: pointer;
  filter: brightness(var(--bright));
  box-shadow: 0 0 4px #a6a6a6;
}
.check-box[disabled],
.check-cont.disabled {
  pointer-events: none;
  cursor: default !important;
}

.check-box:after {
  align-self: center;
  width: 6px;
  height: 10px;
  border: solid var(--text);
  border-width: 0 2px 2px 0;

  content: "";
  display: none;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input:checked ~ .check-box {
  background-color: var(--primary_dark);
}
input:checked ~ .check-box:after {
  display: block;
}
input:checked ~ .checkmark {
  background-color: var(--primary_dark);
}

input.rc-checkbox-input {
  background-color: var(--secondary_dark);
  color: var(--text);
  padding: 5px;
}

input.form-control[disabled] {
  background-color: var(--background);
  filter: brightness(var(--disabled));
}
