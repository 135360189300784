.NotFound {
  position: relative;
  z-index: 100;
  padding-top: 100px;
  text-align: center;
  height: 100%;
  color: #f2f2f2;
  font-family: "Montserrat script=all rev=1", sans-serif;
}

.not-found-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(./splash.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.NotFound .body {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NotFound img.splash {
  left: 50%;
  top: 50%;
  width: 155%;
  transform: translate(-2%, 65%);
}

.NotFound .body h3 {
  position: relative;
  z-index: 1;
  font-weight: thin;
  font-size: 6rem;
}

.NotFound .body .btn {
  font-size: 2.5rem;
  padding: 0.75rem 12rem;
  margin-top: 2rem;
  z-index: 10;
  position: relative;
}
