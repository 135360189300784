.filter-bar {
  position: absolute;
  display: none;
  flex-direction: row-reverse;
  bottom: 0;
  right: 70px;
  padding: 10px;
  max-height: 86px;
  /* overflow: hidden; */
  background-color: var(--secondary);
  /* opacity: 0; */
  /* transition: opacity 100ms; */
}

.filter-bar.open {
  /* opacity: 100%; */
  display: flex;
  align-items: center;
}

.filter-bar > *,
.filter-bar .filter-op > * {
  margin-right: 8px;
}

.filter-bar .sep-v {
  height: 68px;
  margin: 0 9px 0px 0px;
}

/* .filter-bar > *:last-child, */
.filter-bar .filter-op > *:last-child {
  margin-right: 0px;
}

.filter-bar .filter-op {
  display: flex;
  flex-direction: row;
}

.filter-op .desc {
  padding: 6px 0;
  /* cursor: help; */
}
.filter-bar .btn {
  display: inline-table;
}
.filter-op .drop-down,
.filter-bar .filter-op input.form-control {
  min-width: 80px;
}
.filter-op .drop-down .r {
  padding: 6px 10px;
}

.filter-bar .filter-op.geo .drop-down {
  min-width: 130px;
}

.filter-bar .time {
  /* margin-right: 8px; */
  display: flex;
  flex-direction: column;
}

.filter-bar .time > *:first-child {
  margin-bottom: 4px;
}

/* -----------------------------------------------------
                    Date time input 
-------------------------------------------------------- */

.filter-bar .filter-op.vertical {
  flex-direction: column;
}

.filter-bar .filter-op .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--text);
  background-color: var(--background);
  border: 0;
  border-radius: 0;
  height: 100%;
  padding-right: 12px;
}

.filter-bar .filter-op .MuiFormControl-root.MuiTextField-root {
  border: 1px solid var(--secondary);
}

.filter-bar .filter-op .MuiFormControl-root.MuiTextField-root,
.filter-bar .filter-op.vertical .desc {
  min-width: 204px;
}

.filter-bar .filter-op.vertical .desc {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-bar .filter-op .MuiFormControl-root.MuiTextField-root.MuiError {
  border: 1px solid var(--error);
}

.filter-bar .filter-op .MuiFormControl-root.MuiTextField-root input {
  padding: 6px 10px;
}

.filter-bar .filter-op .MuiInputAdornment-root {
  margin-left: 0;
  color: var(--text);
}

.filter-bar .filter-op .MuiInputAdornment-root button {
  background-color: color-mix(in srgb, var(--text) 0%, #00000000);
  border-radius: 0;
  padding: 4px;
  margin-right: -6px;
  transition: background-color ease 0.2s;
}

.filter-bar .filter-op .MuiInputAdornment-root button:hover {
  background-color: color-mix(in srgb, var(--text) 15%, #00000000);
}

.filter-bar .filter-op .MuiInputAdornment-root svg {
  fill: var(--text);
}

.filter-bar .filter-op fieldset.MuiOutlinedInput-notchedOutline {
  border: 0;
}

.filter-op span.MuiSlider-root {
  margin: 0 12px;
  min-width: 200px;
}

.filter-op.sliders {
  margin-right: 32px;
}

.filter-bar .MuiSlider-root {
  margin: 0 12px !important;
  padding: 15px 0 5px;
}

.filter-bar .MuiSlider-root .MuiSlider-rail {
  background-color: var(--secondary_dark);
  opacity: 1;
}

.filter-bar .MuiSlider-valueLabelOpen,
.filter-bar .MuiSlider-valueLabel {
  background-color: var(--background);
  padding: 8px 12px;
  font-size: 12px;
}

.filter-bar .filter-op .MuiSlider-track,
.filter-bar .filter-op .MuiSlider-thumb {
  color: var(--primary_dark);
}

.filter-bar .filter-op .MuiSlider-track {
  opacity: 0.5;
}

.filter-bar .filter-op .MuiSlider-thumb,
.filter-bar .MuiSlider-valueLabelOpen,
.filter-bar .MuiSlider-valueLabel {
  box-shadow: 0px 2px 8px 0.44px #00000063;
}

.filter-bar .filter-op .slider-marks.MuiSlider-root {
  padding: 10px 0;
  margin-top: -5px !important;
  pointer-events: none;
}

.filter-bar .filter-op .slider-marks .MuiSlider-track,
.filter-bar .filter-op .slider-marks .MuiSlider-thumb,
.filter-bar .filter-op .slider-marks .MuiSlider-rail {
  color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.filter-bar .filter-op .slider-marks .MuiSlider-mark,
.filter-bar .filter-op .slider-marks .MuiSlider-markLabel {
  color: var(--text);
}

.filter-bar .filter-op .slider-marks .MuiSlider-markLabel {
  font-family: Montserrat !important;
  font-size: 10px;
  top: 20px;
}

.filter-bar .filter-op .slider-marks .MuiSlider-mark {
  height: 6px;
  border-radius: 0;
}
